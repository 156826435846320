import { createRouter, createWebHistory } from 'vue-router';
import { Auth } from 'aws-amplify';

const routes = [
  {
    path: '/',
    name: 'booking',
    component: () => import ('../views/BookingView.vue')
  },
  {
    path: '/mes-reservations',
    name: 'mesReservations',
    component: () => import ('../views/myBookingView.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import ('@/views/AdminView.vue'),
    beforeEnter: async () => {
      console.log("authorization");
      let authorized = false;
      await Auth.currentAuthenticatedUser()
      .then(user => { 
        
        if (user.signInUserSession.accessToken.payload["cognito:groups"].includes('admin')) {
          authorized = true;
        }
      })
      .then(data => console.log(data))
      .catch(err => console.log(err));

      return authorized;
    },
  },
  {
    path: '/admin2',
    name: 'admin2',
    component: () => import ('@/views/Admin2View.vue'),
    beforeEnter: async () => {
      console.log("authorization");
      let authorized = false;
      await Auth.currentAuthenticatedUser()
      .then(user => { 
        
        if (user.signInUserSession.accessToken.payload["cognito:groups"].includes('admin')) {
          authorized = true;
        }
      })
      .then(data => console.log(data))
      .catch(err => console.log(err));

      return authorized;
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import ('@/views/HelpView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router