<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
    <div class="w-full mx-auto flex-1">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <DisclosurePanel class="bg-gray-300 px-4 py-2 origin-top-right grid-rows-2 rounded-md text-sm text-gray-700">
              <a v-for="item in navigation" :key="item.name" :href="item.href" class="flex " :aria-current="item.href == this.$route.href ? 'page' : undefined">{{ item.name }}</a>
            </DisclosurePanel>
          </transition>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <img class="block lg:hidden h-8 w-auto" src="@/assets/tennisBall.svg" alt="Workflow" />
            <img class="hidden lg:block h-8 w-auto" src="@/assets/tennisBallText.svg" alt="Workflow" />
          </div>
          <div class="hidden sm:block sm:ml-6 ">
            <div class="flex space-x-4">
              <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.href == this.$route.href ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.href == this.$route.href ? 'page' : undefined">{{ item.name }}</a> 
            </div>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
<!--           <button type="button" class="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button> -->

          <!-- Profile dropdown -->
          <Menu as="div" class="ml-3 relative">
            <div>
              <MenuButton class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span class="sr-only">Open user menu</span>
                <img class="rounded-full w-11 h-11" :src="calHash" alt="" />
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem v-slot="{ active }">
                  <a href="#" :class="[active ? 'bg-gray-300' : '', 'block px-4 py-2 text-sm text-gray-700']" @click="$emit('signoutEvent')">se déconnecter</a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
  </Disclosure>
  
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { /*BellIcon,*/ MenuIcon, XIcon } from '@heroicons/vue/outline';
//import Gravatar from 'vue-gravatar';

let navigation = [
  { name: 'Reservations', href: '/', current: true },
  { name: 'Mes reservations', href: '/mes-reservations', current: false },
  { name: 'Aide', href: '/help', current: false }
]


export default {
  async created() {
    navigation = [
      { name: 'Reservations', href: '/', current: true },
      { name: 'Mes reservations', href: '/mes-reservations', current: false },
    ]
    this.adminPage()
  },
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    //BellIcon,
    MenuIcon,
    XIcon,
    
  },
  
  setup() {
    return {
      navigation,
    }
  },

  props: ['user'],
  computed: {
    calHash() {
      var md5 = require("md5") ;
      var hashUser = "https://www.gravatar.com/avatar/" + md5(this.user.attributes.email);
      return hashUser;
    }
  },

  methods: { 
    adminPage() {
      if(this.user.signInUserSession.accessToken.payload["cognito:groups"].includes("admin") ) {
        this.navigation.push({ name: 'Admin', href: '/admin', current: false });
        this.navigation.push({ name: 'Admin reservations', href: '/admin2', current: false });
      }
    }
  }


}
</script>

<style scoped>

.router-link-active {
  background-color: rgb(59, 59, 59);
  color: white;
}
</style>